import React from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./styles.module.css";

const RootGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingInline: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    paddingInline: "14rem",
  },
  backgroundColor: "#000000",
  color: "#FFFFFF",
}));

const projects = [
  {
    idx: 1,
    projectSrc:
      "https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate1.png",
    header: "Thinking big",
    project: "https://itrexgroup.com/services/technology-consulting/",
    caption: `After digging deep to understand holistically your challenges and business objectives, we chart your technology path that will keep your business future-proof.`,
  },
  {
    idx: 2,
    projectSrc:
      "https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate2.png",
    header: "Starting small",
    project: "https://itrexgroup.com/services/technology-consulting/",
    caption: `At the beginning of the transformation journey, we start with simple use cases that bring you immediate results.`,
  },
  {
    idx: 3,
    projectSrc:
      "https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate3.png",
    header: "Creating value fast",
    project: "https://itrexgroup.com/services/technology-consulting/",
    caption: `We then build an MVP, testing the first use cases and collecting feedback. Along the way, we introduce enhancements to align business deliverables and add new use cases.`,
  },
  {
    idx: 4,
    projectSrc:
      "https://itrexgroup.com/wp-content/themes/itrex/assets/images/main_page/collaborate4.png",
    header: "Innovating at scale",
    project: "https://itrexgroup.com/services/technology-consulting/",
    caption: `We are expanding the functionality of your system, keeping it flexible on the tech stack, hugely adaptable to humans, and easily scalable to evolve along with your business growth.`,
  },
];

function Collabrate() {
  return (
    <RootGrid container id="features">
      <Grid item xs={12} sx={{ mt: { xs: 4, md: 16 } }}>
        <Typography
          variant="h3"
          sx={{ fontSize: { xs: "2rem", md: "3rem" }, fontWeight: "600" }}
        >
          How we collaborate with you
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: { xs: 6, md: 12 },
        }}
      >
        {projects.map((project) => (
          <Grid
            item
            xs={12}
            md={5.5}
            key={project.idx}
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              mt: 5,
              justifyContent: "space-around",
            }}
          >
            <Stack
              sx={{
                width: { xs: "6rem", md: "10rem" },
                height: { xs: "6rem", md: "10rem" },
              }}
            >
              <img
                className={styles.collabrate}
                src={project.projectSrc}
                alt="collabrate"
              />
            </Stack>
            <Stack width="5rem" />
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: { xs: 5, md: 0 },
              }}
            >
              <Typography fontSize="1.5rem" fontWeight="600">
                {project.header}
              </Typography>
              <Typography sx={{ mt: 2, opacity: 0.5 }}>
                {project.caption}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ height: "6rem" }} />
      <Grid item xs={12}>
        {/* <Button
          sx={{
            background: "#4861FC",
            color: "#FFFFFF",
            padding: "1rem 5rem",
            textTransform: "none",
            fontSize: "1rem",
            "&:hover": {
              background: "#1e40af",
            },
          }}
        >
          Let's Collabrate
        </Button> */}
      </Grid>
      <Grid item xs={12} sx={{ height: "6rem" }} />
    </RootGrid>
  );
}

export default Collabrate;

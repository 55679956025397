import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { appName } from "../../config/appCfg";
import assetsConsts from "../../constants/assetConsts";
import { styled } from "@mui/material/styles";

const TypographyStyle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.25rem",
  },
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
}));

function Header() {
  const [scrolling, setScrolling] = useState(false);
  const changeHeaderStyle = () => {
    if (window.scrollY >= 150) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeHeaderStyle);
    return () => window.removeEventListener("scroll", changeHeaderStyle);
  }, []);

  const RootGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      // marginBottom: "0.5rem",
      // marginTop: "0.625rem",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: scrolling? "0" : "1.25rem",
    },
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    fontFamily: "SuisseIntl",
    lineHeight: "1.25rem",
    li: {
      display: "inline",
      a: {
        color: "#FFFFFF",
        textDecoration: "none",
      },
      marginRight: "30px",
      fontSize: "20px",
      "&:hover": {
        color: "#1976d2",
      },
      flexDirection: "row",
    },
    background: scrolling ? "#000000": "transparent",
    zIndex: 1,
    alignItems: 'center',
  }));

  return (
    <RootGrid
      container
      id="main-header"
      onScroll={changeHeaderStyle}
    >
      <Grid item xs={0.5} md={1.5} display="flex" />
      <Grid
        item
        xs={2.5}
        md={1.5}
        sx={{ height: scrolling ? "3.5rem": "4rem", display: "flex", flexDirection: "row" }}
      >
        <img
          alt="logo"
          src={assetsConsts.logo}
          style={{
            width: scrolling ? "3.5rem": "4rem",
            height: scrolling ? "3.5rem": "4rem",
            objectFit: "contain",
          }}
        />
        <TypographyStyle sx={{fontSize: {xs: scrolling ? "1.5rem": "1.75rem", md: scrolling ? "1.8rem": "2.25rem"} }}>{appName}</TypographyStyle>
      </Grid>
      <Grid
        item
        xs={0} //9
        md={6}
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "center",
          color: "#FFFFFF",
        }}
      >
        <ul style={{ display: 'flex' }}>
          <li>
            <a href="#features"><Typography sx={{ fontSize: scrolling ? "1rem" : '1.25rem' }}>Features</Typography></a>
          </li>
          <li>
            <a href="#use-cases"><Typography sx={{ fontSize: scrolling ? "1rem" : '1.25rem' }}>Use cases</Typography></a>
          </li>
          {/* <li>
            <a href="#pricing"><Typography sx={{ fontSize: scrolling ? "1rem" : '1.25rem' }}>Pricing</Typography></a>
          </li> */}
        </ul>
      </Grid>
      <Grid item xs={0} md={3} display="block" />
    </RootGrid>
  );
}

export default Header;

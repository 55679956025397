import React, { useState } from "react";
import {
  Button,
  Grid,
  Radio,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import AxiosApiService from "../../services/axios-api";
// import AttachFileIcon from "@mui/icons-material/AttachFile";

const RootGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingInline: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    paddingInline: "14rem",
  },
  backgroundImage: "linear-gradient(60deg, #27272a, #18181b)",
  color: "#FFFFFF",
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    paddingInline: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    paddingInline: "8rem",
    color: "#FFFFFF",
  },
  width: "100%",
  opacity: 0.5,
  background: "#71717a",
  "&:hover": {
    opacity: 1,
    background: "#4861FC",
  },
  color: "#FFFFFF",
  padding: "1rem 5rem",
  textTransform: "none",
  fontSize: "1rem",
  fontWeight: 600,
  marginTop: "4rem",
}));

function ContactUs() {
  const [selectedValue, setSelectedValue] = useState("a");

  const handleChange = (event) => {
    console.log("handleChange: ", event.target.value);
    setSelectedValue(event.target.value);
  };

  const validationSchema = yup.object({
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    name: yup.string("Enter your name").required("Name is required"),
    phoneNum: yup.string("Enter your phone number").required("Phone number is required"),
    message: yup.string("Enter your message").required("Message is required"),
  });

  const handleSubmit = async (values) => {
    // setLoading(true);
    try {
      // yup.validate(values);
      await validationSchema.validate(values);
      // console.log('Data is valid');
      console.log("values: ", values);
      toast.info("Message could not be sent! insufficient data" , {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      // toast.error("Message could not be sent");
      // toast("Wow so easy!");

      await AxiosApiService.post('cloud_function','/v1/send-email','emailErr', values);
    } catch (error) {
      console.log("error: ", error);

      toast.error("Message could not be sent");
    }
    // setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
      phoneNum: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });
  return (
    <RootGrid container sx={{ mt: { xs: 8, md: 0 } }}>
      <Grid item xs={12} sx={{ mt: { xs: 8, md: 16 } }}>
        <Typography variant="h3" fontWeight="600">
          Contact Us
        </Typography>
      </Grid>
      <Grid item xs={12} height="4rem" />
      <Grid container>
        <form
          onSubmit={formik.handleSubmit}
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Stack spacing={1} sx={{ width: { xs: "100%", md: "45%" } }}>
            <Typography sx={{ marginTop: "21px", fontWeight: "bold" }}>
              Name
            </Typography>
            <TextField
              variant="standard"
              placeholder="Your name"
              fullWidth
              name="name"
              sx={{
                fontSize: "0.8rem",
                color: "#FFFFFF",
                input: { color: "#ffdb3b" },
              }}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Stack>
          <Stack spacing={1} sx={{ width: { xs: "100%", md: "45%" } }}>
            <Typography sx={{ marginTop: "21px", fontWeight: "bold" }}>
              Email ID
            </Typography>
            <TextField
              variant="standard"
              placeholder="example@gmail.com"
              fullWidth
              name="email"
              sx={{ fontSize: "0.8rem", color: "#FFFFFF", input: { color: '#ffdb3b' } }}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Stack>
          <Stack spacing={1} sx={{ width: { xs: "100%", md: "45%" } }}>
            <Typography sx={{ marginTop: "21px", fontWeight: "bold" }}>
              Phone
            </Typography>
            <TextField
              variant="standard"
              placeholder="9XXXXXXXXX"
              fullWidth
              name="phoneNum"
              sx={{ fontSize: "0.8rem", color: "#FFFFFF", input: { color: '#ffdb3b' } }}
              value={formik.values.phoneNum}
              onChange={formik.handleChange}
              error={formik.touched.phoneNum && Boolean(formik.errors.phoneNum)}
              helperText={formik.touched.phoneNum && formik.errors.phoneNum}
            />
          </Stack>
          <Stack spacing={1} sx={{ width: { xs: "100%", md: "45%" } }}>
            <Typography sx={{ marginTop: "21px", fontWeight: "bold" }}>
              Message
            </Typography>
            <TextField
              variant="standard"
              placeholder="Hi there! can we set a demo"
              fullWidth
              name="message"
              sx={{ fontSize: "0.8rem", color: "#FFFFFF", input: { color: '#ffdb3b' } }}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} mt={3}>
            {/* <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Stack sx={{ color: "#ffdb3b" }}>
                <AttachFileIcon />
              </Stack>
              <Typography>
                Attach your file <br />
                <p style={{ opacity: "0.5", margin: 0 }}>up to 20MB</p>
              </Typography>
            </Stack> */}
          </Stack>
          <Stack sx={{ width: { xs: "100%", md: "45%" } }} mt={3}>
            <Typography sx={{ opacity: "0.5", fontSize: "0.75rem" }}>
              We will process your personal information in accordance with our
              Privacy Policy.
            </Typography>
            {/* <Stack sx={{ display: "flex", flexDirection: "row" }}>
              <Radio
                checked={selectedValue === "a" ? true : false}
                onChange={handleChange}
                value={selectedValue}
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <Typography sx={{ opacity: "0.5", fontSize: "0.75rem", mt: 1.5 }}>
                I would like to be contacted with news and updates about your
                events and services
              </Typography>
            </Stack> */}
          </Stack>

          <SubmitButton type="submit">Send Message</SubmitButton>

          {/* <Button type="submit">Submit</Button> */}
        </form>
      </Grid>
      <ToastContainer />
      <Grid item xs={12} sx={{ height: { xs: "2rem", md: "6rem" } }} />
      {/* <Grid item xs={12}>
        <SubmitButton type="submit">Send Message</SubmitButton>
      </Grid> */}
      <Grid item xs={12} sx={{ height: { xs: "2rem", md: "6rem" } }} />
    </RootGrid>
  );
}

export default ContactUs;

const isDevMode = JSON.parse(process.env.REACT_APP_DEV_MODE);


const PROD_CONFIG = {
    firebaseConfig: {
        apiKey: 'AIzaSyAMxrM31dlLrmOaqoGleoqa0AyrKp-nqm0',
        authDomain: 'zaide-ai-prod.firebaseapp.com',
        projectId: 'zaide-ai-prod',
        storageBucket: 'zaide-ai-prod.appspot.com',
        messagingSenderId: '32026816288',
        appId: '1:32026816288:web:5149d94d789c4c0db2f145',
        measurementId: 'G-KD9F0QRNQK',
    },
    cloudFunctionBaseUrl:
        'https://us-central1-zaide-ai-prod.cloudfunctions.net',
    // 'https://us-central1-zaide-ai-prod.cloudfunctions.net',
    // 'http://127.0.0.1:5001/zaide-ai-prod/us-central1'
};

const DEV_CONFIG = {
    firebaseConfig: {
        apiKey: 'AIzaSyC4ZquZLr0I5W7uWtnok35sFQU84wZBOzY',
        authDomain: 'zaide-ai--dev.firebaseapp.com',
        projectId: 'zaide-ai--dev',
        storageBucket: 'zaide-ai--dev.appspot.com',
        messagingSenderId: '800289282343',
        appId: '1:800289282343:web:eb31bf91a896ecd9303102',
        measurementId: 'G-DMEX3PECHK',
    },
    cloudFunctionBaseUrl:
        'https://us-central1-zaide-ai--dev.cloudfunctions.net',
    // 'https://us-central1-zaide-ai--dev.cloudfunctions.net',
    // 'http://127.0.0.1:5001/zaide-ai--dev/us-central1'
};

const config = isDevMode ? DEV_CONFIG : PROD_CONFIG;

export default config;

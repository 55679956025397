/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, Stack, Typography } from "@mui/material";
import assetsConsts from "../../constants/assetConsts";
import styles from "./styles.module.css";

const RootGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    padding: "6rem 0",
  },
  [theme.breakpoints.up("md")]: {
    padding: "6rem 0",
  },
  backgroundColor: "#000000",
  color: "#FFFFFF",
}));

const TypographyStyle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "1.75rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.25rem",
  },
  display: "flex",
  alignItems: "center",
  color: "#FFFFFF",
}));
const logos = [
  "https://storage.googleapis.com/zaide-ai-prod.appspot.com/logos/logo-iit.png",
  "https://storage.googleapis.com/zaide-ai-prod.appspot.com/logos/KTH.png",
  "https://storage.googleapis.com/zaide-ai-prod.appspot.com/logos/NRW.png",
  "https://storage.googleapis.com/zaide-ai-prod.appspot.com/logos/Google-logo.png",
  "https://storage.googleapis.com/zaide-ai-prod.appspot.com/logos/TU-Berlin-Logo.png",
  "https://storage.googleapis.com/zaide-ai-prod.appspot.com/logos/th-owl.png",
];
function AboutUs() {
  return (
    <RootGrid container>
      <Grid container>
        <Grid item xs={0.5} md={1.5} />
        <Grid
          item
          xs={11.5}
          md={1.5}
          sx={{ height: "4rem", display: "flex", flexDirection: "row" }}
        >
          <img
            alt="logo"
            src={assetsConsts.logo}
            style={{
              width: "4rem",
              height: "4rem",
              objectFit: "contain",
            }}
          />
          <TypographyStyle>Zaide</TypographyStyle>
        </Grid>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={10} md={6.5} sx={{ mt: { xs: 5, md: 0 } }}>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "1.8rem", md: "3rem" },
              fontWeight: { xs: "600", md: "400" },
            }}
          >
            We are an enterprise software development company that is inspired
            by the future
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid item xs={12} height="4rem" />

      <Grid container>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={11} md={1.5}>
          <ul
            style={{
              fontWeight: 400,
              listStyle: "none",
              lineHeight: "1.8rem",
              margin: 0,
              padding: 0,
            }}
          >
            <li style={{ fontSize: "1.5rem" }}>Offices:</li>
            <li style={{ opacity: 0.5, fontSize: "1.25rem" }}>
            San Francisco, USA
              {/* Berlin, Germany */}
            </li>
            <li style={{ opacity: 0.5, fontSize: "1.25rem" }}>
              {/* Gurugram, India */}
              Berlin, Germany
            </li>
            <li style={{ opacity: 0.5, fontSize: "1.25rem" }}>
              {/* Aliso Viejo, CA */}
              New Delhi, India
            </li>
          </ul>
        </Grid>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={10} md={6.5}>
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: 400,
              mt: { xs: "4rem", md: "0" },
            }}
          >
            parth@zaide.ai
          </Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid item xs={12} height="4rem" />

      <Grid container>
        <Grid item xs={1} md={1.5} />
        <Grid
          item
          xs={11}
          md={1.5}
          sx={{
            display: "flex",
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <ul
            style={{
              fontWeight: 600,
              listStyle: "none",
              margin: 0,
              opacity: 0.5,
              fontSize: { xs: "1rem", md: "0.75rem" },
              padding: 0,
            }}
          >
            <li>Privacy Policy</li>
            <li>©2023 Zaide Group</li>
          </ul>
        </Grid>
        <Grid item xs={1} md={1.5} />
        <Grid item xs={10} md={6.5}>
          <Stack
            sx={{
              width: "100%",
              overflowX: "auto",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              mt: { xs: "3rem", md: "0" },
            }}
          >
            {logos.map((logo) => (
              <a key={logo}>
                <img
                  style={{ maxWidth: "6.375rem", maxHeight: "5.313rem", marginInline: '0.5rem' }}
                  loading="lazy"
                  data-src={logo}
                  className="lazyloaded"
                  src={logo}
                />
                <noscript>
                  <img loading="lazy" src={logo} />
                </noscript>
              </a>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </RootGrid>
  );
}

export default AboutUs;

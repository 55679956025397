import React from "react";
import { Button, Grid, Stack, Typography, IconButton } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
// import styles from "./styles.module.css";
import { styled } from "@mui/material/styles";

const RootGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    // marginBottom: "0.5rem",
    // marginTop: "0.625rem",
    paddingInline: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    //   paddingTop: "1.25rem",
    paddingInline: "14rem",
  },
}));

const projects = [
  {
    projectSrc:
      "https://storage.googleapis.com/zaide-ai-prod.appspot.com/assets/demo-chat.png",
    header: "AI-based document comprehension and querying",
    project: "https://zaide-ai-console-dev.web.app/",
    caption: `Self-service analytics enabled by AI algorithms empowers
    users with instant actionable insights.`,
    idx: 1,
  },
  {
    projectSrc:
    "https://storage.googleapis.com/zaide-ai--dev.appspot.com/assets/vision-sample.jpg",
      // "https://storage.googleapis.com/zaide-ai--dev.appspot.com/assets/computer-vision.png",
    header: "Computer Vision",
    project: "https://zaide-ai-console-dev.web.app/vision",
    idx: 2,
    caption: `Zaide biotech platform relying on organ-chips enables faster, safer and cheaper human recognition system`,
  },
];

function LatestProjects() {
  return (
    <RootGrid container>
      <Grid item xs={12} mt={16}>
        <Typography
          variant="h3"
          sx={{ fontSize: { xs: "2rem", md: "3rem" }, fontWeight: "600" }}
        >
          Latest Projects
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          mt: { xs: 4, md: 12 },
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {projects.map((project) => (
          <Grid
            item
            xs={12}
            md={5.5}
            key={project.idx}
            sx={{ mt: { xs: 4, md: 0 } }}
          >
            <Stack sx={{ width: "100%", height: { xs: "15rem", md: "20rem" } }}>
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                src={project.projectSrc}
                alt="nlp"
              />
            </Stack>
            <a
              href={project.project}
              target="_blank"
              rel="noreferrer"
              style={{
                color: "#000",
                opacity: 1,
                textDecoration: "auto",
              }}
            >
              <Typography mt={3} sx={{ "&:hover": { opacity: 0.7, textDecoration: 'underline' } }} fontSize="1.5rem" fontWeight="600">
                {project.header}
              </Typography>
            </a>
            <Typography mt={2}>{project.caption}</Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ height: "6rem" }} />
    </RootGrid>
  );
}

export default LatestProjects;

import axios from 'axios';
import { getAuth } from 'firebase/auth';

import firebaseConfig from '../config/firebase';

const TIME_OUT = 20 * 1000;

const AxiosApiService = {
    /**
     *
     * @param {'cloud_function' | 'other'} requestType 'cloud_function' | 'other'
     * @param {string} endpoint Complete url if not cloud_function call, else only end    point
     * @param errorCode
     * @param {number} timeout Request timeout, default is 10000
     * @returns {Promise} Promise Object
     */
    get: async (requestType, endpoint, errorCode, timeout = TIME_OUT) => {
        try {
            if (!requestType && !endpoint && !errorCode) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    code: 123,
                    message: 'Check parameters',
                });
            }

            let url;
            let config;

            if (requestType === 'cloud_function') {
                // eslint-disable-next-line no-use-before-define
                url = createCloudFunctionUrl(endpoint);

                // eslint-disable-next-line no-use-before-define
                config = await createCloudFunctionConfig(timeout);
            } else {
                url = endpoint;
            }

            const response = await axios.get(url, config);

            return Promise.resolve(response.data);
        } catch (error) {
            console.log('AxiosApiService Response Error123 => ', error);

            return Promise.reject(error);
        }
    },

    /**
     *
     * @param {'cloud_function' | 'other'} requestType 'cloud_function' | 'other'
     * @param {string} endpoint Complete url if not cloud_function call, else only end point
     * @param {string} errorCode Error code for logging
     * @param {any} data Data to send with POST request
     * @param {number} timeout Request timeout, default is 10000
     * @param {boolean} fileUpload File to upload
     * @param bypassToken
     * @returns {Promise} Promise Object
     */
    post: async (
        requestType,
        endpoint,
        errorCode,
        data,
        timeout = TIME_OUT,
        fileUpload = false,
        bypassToken = false
    ) => {
        try {
            if (!requestType && !endpoint && !errorCode && !data) {
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    code: 123,
                    message: 'Check parameters',
                });
            }

            let url;
            let config;

            if (requestType === 'cloud_function') {
                // eslint-disable-next-line no-use-before-define
                url = createCloudFunctionUrl(endpoint);
                if (!bypassToken) {
                    // eslint-disable-next-line no-use-before-define
                    config = await createCloudFunctionConfig(
                        timeout,
                        fileUpload
                    );
                }
            } else {
                url = endpoint;
            }
            const response = await axios.post(url, data, config);

            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    },
};

function createCloudFunctionUrl(endpoint) {
    const baseUrl = `${firebaseConfig.cloudFunctionBaseUrl}/capi`;

    return `${baseUrl}${endpoint}`;
}

async function createCloudFunctionConfig(timeout, fileUpload = false) {
    const token = await getAuth().currentUser.getIdToken();

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        timeout,
    };

    if (fileUpload) {
        config.headers['Content-Type'] = 'multipart/form-data';
    }

    return config;
}

export default AxiosApiService;

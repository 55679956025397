import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, Stack, Typography } from "@mui/material";
import styles from "./styles.module.css";

const RootGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    marginTop: "6rem",
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "6.25rem",
  },
  fontFamily: "Roboto', sans-serif",
}));

const ProblemTypo = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    //   marginTop: "6rem",
  },
  [theme.breakpoints.up("md")]: {
    //   marginTop: "6.25rem",
  },
  fontFamily: "Roboto', sans-serif",
  background: `radial-gradient(circle at 0% 0%, rgb(246, 0, 254) 0%, rgb(201, 0, 254) 25%, rgb(161, 54, 255) 50%, rgb(81, 66, 240) 75%, rgb(0, 51, 217) 100%)`,
  width: "fit-content",
  paddingInline: "2rem",
  borderRadius: "0.5rem",
  color: "#ffffff",
}));

const ProblemArr = [
  {
    probImg: "/hands.png",
    probNum: "Problem 1",
    probContent:
      "Many companies want to leverage AI but lack the in-house expertise to develop custom AI solutions",
  },

  {
    probImg: "/personel.png",
    probNum: "Problem 2",
    probContent:
      "There is a shortage of AI talent in the job market, and hiring or upskilling employees with AI expertise can be time-consuming and expensive.",
  },

  {
    probImg: "/cart.png",
    probNum: "Problem 3",
    probContent:
      "Maintaining data privacy and security, especially in industries with strict regulations like healthcare and finance, can be a significant hurdle. Companies need to ensure AI solutions comply with data protection laws.",
  },
];

function Challenges() {
  return (
    <RootGrid container>
      <Grid container sx={{ mt: { xs: 6, md: 12 } }}>
        <Grid
          item
          xs={0}
          md={4}
          sx={{ overflow: "hidden", display: { xs: "none", md: "block" } }}
        >
          <Stack sx={{ position: "relative", height: "100%", float: "right" }}>
            <Stack
              sx={{
                transform: "rotate(130deg)",
                position: "absolute",
                left: "-36rem",
                top: "-10rem",
              }}
            >
              <img src="/pentagon.png" alt="pentagon" />
            </Stack>
          </Stack>
          <Stack id="small-dot" className={styles["small-dot"]} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography
            sx={{
              fontSize: "2.875rem",
              fontWeight: "bold",
              mt: 3,
              ml: 11,
            }}
          >
            Problems
          </Typography>
          {ProblemArr.map((item) => (
            <Grid container sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
              <Grid item xs={3} md={2} sx={{ width: { xs: "5rem", md: "80px" }, mr: 1 }}>
                <img
                  style={{ width: "100%", objectFit: "contain" }}
                  src={item.probImg}
                  alt="cart"
                />
              </Grid>
              <Grid item xs={8} sx={{ display: "flex", flexDirection: "column", pt: 1 }}>
                <ProblemTypo>{item.probNum}</ProblemTypo>
                <Typography
                  sx={{
                    ml: 2,
                    width: { md: "31.25rem" },
                    color: `rgb(16, 16, 16)`,
                  }}
                >
                  {item.probContent}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container sx={{ mt: {xs: 6, md: 12}}}>
        <Grid item xs={1} />
        <Grid item xs={12} md={6}>
          <Typography
            sx={{
              fontSize: "2.875rem",
              fontWeight: "bold",
              mt: 8,
            }}
          >
            Solution
          </Typography>
          <Typography sx={{ width: {xs: "100%", md: "500px"}, color: `rgb(16, 16, 16)` }}>
            Zaide.ai provides end-to-end AI development services and expertise
            to enable companies to build AI products tailored to their needs
          </Typography>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 6,
              justifyContent: "space-evenly",
            }}
          >
            <Stack sx={{ width: "100px", alignItems: "center" }}>
              <img
                style={{ width: "100%", objectFit: "contain" }}
                src="/write-file.png"
                alt="write"
              />
              <Typography
                sx={{
                  fontSize: "1.275rem",
                  fontWeight: "700",
                  mb: 1,
                  mr: 1,
                  mt: 2,
                }}
              >
                Research
              </Typography>
            </Stack>
            <Stack sx={{ width: "100px", alignItems: "center" }}>
              <img
                style={{ width: "100%", objectFit: "contain" }}
                src="/file-search.png"
                alt="write"
              />
              <Typography
                sx={{
                  fontSize: "1.275rem",
                  fontWeight: "700",
                  mb: 1,
                  mr: 1,
                  mt: 2,
                }}
              >
                Develop
              </Typography>
            </Stack>
            <Stack sx={{ width: "100px", alignItems: "center" }}>
              <img
                style={{ width: "100%", objectFit: "contain" }}
                src="/bulb.png"
                alt="write"
              />
              <Typography
                sx={{
                  fontSize: "1.275rem",
                  fontWeight: "700",
                  mb: 1,
                  mr: 1,
                  mt: 2,
                }}
              >
                Deploy
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={0} md={5} sx={{ display: {xs: "none", md: "block"}}}>
          <Stack
            className={styles["solution-animation"]}
            sx={{ float: "right" }}
          />
        </Grid>
      </Grid>
    </RootGrid>
  );
}

export default Challenges;

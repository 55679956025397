const assetsConsts = Object.freeze({
  landing_page_bg:
    "https://storage.googleapis.com/zaide-ai-prod.appspot.com/assets/landing_page.gif",
  logo: "https://storage.googleapis.com/zaide-ai-prod.appspot.com/assets/zaide_symbol_round.png",
  theme: '#fff, #06101e 42%, #06101e',
  btn_theme: '163deg, #6066ff 2%, #ffb5f5',
  brand_theme: '#06101e, #fff 57%, #fff' 
});

export default assetsConsts;

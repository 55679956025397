/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { Button, Grid, Stack, Typography } from "@mui/material";
import styles from "./styles.module.css";
import { styled } from "@mui/material/styles";
import assetsConsts from "../../constants/assetConsts";

const SliderItem = styled(Stack)(({ theme }) => ({
  // [theme.breakpoints.up("xs")]: {
  //   marginTop: "6rem",
  // },
  // [theme.breakpoints.up("md")]: {
  //   marginTop: "6.25rem",
  // },
  // backgroundColor: '#000',
  border: "1px #eeeff1",
  borderRadius: "0.5rem",
  padding: "12px 24px",
  fontSize: "20px",
  lineHeight: "21.8px",
  alignItems: "center",
  textWrap: "nowrap",
}));

const leftSliderContent = [
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/2.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/3.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/4.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/5.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/6.png",
  "https://s3-us-west-2.amazonaws.com/s.cdpn.io/557257/7.png",
];
const BrandStyle = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "3.825rem",
    lineHeight: "33px",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "5.275rem",
    lineHeight: "84px",
  },
  width: "inherit",
  display: "flex",
  flexDirection: "row",
  // backgroundColor: '#000000',
}));

function Introduction() {
  const [text, setText] = useState();
  const AutoType = () => {
    const arr = ["new", "future", "value"];
    const item = arr[Math.floor(Math.random() * arr.length)];
    let currText = "";

    for (let currIdx = 0; currIdx < item.length; currIdx++) {
      setTimeout(() => {
        currText += item[currIdx];
        setText(currText);
      }, currIdx * 600);
    }
  };

  useEffect(() => {
    const interval = setInterval(AutoType, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} height="100vh">
        <Stack
          id="pg-img"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            overflowX: "hidden",
          }}
        >
          <img
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            src={assetsConsts.landing_page_bg}
            alt="main-video"
          />
        </Stack>
        <Stack sx={{ position: "absolute", top: {xs: "30%", md: "40%"}, left: {xs: "10%", md: "15%"} }}>
          <Typography variant="h2" sx={{ color: "#FFDB3B", fontWeight: "600", fontSize: {xs: "1.8rem", md: "3.75rem"} }}>
            Next-gen enterprise
          </Typography>
          <Typography variant="h2" sx={{ color: "#FFFFFF", fontWeight: "600", fontSize: {xs: "1.8rem", md: "3.75rem"}, width: {xs: "50%", md: "100%" } }}>
            software development company
          </Typography>
        </Stack>
        <Stack
          sx={{
            position: "absolute",
            top: {xs: "70%", md: "77%"},
            left: {xs: "10%", md: "15%"},
            flexDirection: "row",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#FFFFFF",
              fontWeight: "600",
              fontSize: {xs: '1.5rem', md: '2.125rem'}
            }}
          >
            We think
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: "#FFDB3B",
              fontWeight: "600",
              fontSize: {xs: '1.5rem', md: '2.125rem'},
              ml: 1
            }}
          >
            {text}
          </Typography>
        </Stack>

        {/* <Button
          sx={{
            background: "#4861FC",
            color: "#FFFFFF",
            padding: { xs: "0.5rem 2rem", md: "1rem 7rem" },
            textTransform: "none",
            fontSize: "1rem",
            width: { xs: "80%", md: "max-content" },
            "&:hover": {
              background: "#1e40af",
            },
            position: "absolute",
            top: {xs: "90%", md: "80%"},
            left: {xs: "50%",md: "75%"},
            transform: `translate(-50%, -50%)`
          }}
        >
          Let's Talk
        </Button> */}
      </Grid>
      <BrandStyle className={styles["marquee"]} item xs={12}>
        {leftSliderContent.map((content) => (
          <Stack
            key={content}
            sx={{
              // backgroundImage:
              //   "linear-gradient(120deg, #6066ff, #ffb5f5 60%, #f4f4f4)",
              borderRadius: "0.5rem",
              padding: "1px",
              margin: "0.5rem",
            }}
          >
            <SliderItem key={content}>
              <img style={{ height: "100px" }} src={content} alt="brand" />
            </SliderItem>
          </Stack>
        ))}
      </BrandStyle>
    </Grid>
  );
}

export default Introduction;

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
// import styles from "./styles.module.css";
import { styled } from "@mui/material/styles";

const RootGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    // marginBottom: "0.5rem",
    // marginTop: "0.625rem",
    paddingInline: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    //   paddingTop: "1.25rem",
    paddingInline: "14rem",
  },

  backgroundColor: "#ffdb3b",
}));

const GridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    // marginBottom: "0.5rem",
    // marginTop: "0.625rem",
    // paddingInline: "3rem",
  },
  [theme.breakpoints.up("md")]: {
    //   paddingTop: "1.25rem",
    // paddingInline: "8rem",
  },

  //   backgroundColor: "#ffdb3b",
  borderTop: "1px solid #808080",
  padding: "1.25rem 0 1.25rem 0",
}));

const portfolioElement = [
  {
    elementSrc:
      "https://itrexgroup.com/wp-content/uploads/2021/11/accord_1-1.gif",
    header: "Innovation Consulting",
    content: `Make your digital transformation journey super simple, safe, and aligned with your specific business needs. We're good at strategy planning for both enterprises and SMBs, IT audit, business process analysis, and more.`,
    idx: "01",
    project: "https://itrexgroup.com/services/technology-consulting/",
  },
  {
    elementSrc:
      "https://itrexgroup.com/wp-content/uploads/2021/11/accord_2-2.gif",
    header: "Digital Transformation",
    content: `Achieve lower costs, utmost scalability and maximum velocity with our legacy software modernization, cloud, and DevOps services. Our enterprise software development company is here to help you remove the roadblocks to innovation.`,
    idx: "02",
    project: "https://itrexgroup.com/services/technology-consulting/",
  },
  {
    elementSrc:
      "https://itrexgroup.com/wp-content/uploads/2021/11/accord_3-2.gif",
    header: "Software Engineering",
    content: `Make your digital transformation journey super simple, safe, and aligned with your specific business needs. We're good at strategy planning for both enterprises and SMBs, IT audit, business process analysis, and more.`,
    idx: "03",
    project: "https://itrexgroup.com/services/technology-consulting/",
  },
  {
    elementSrc:
      "https://itrexgroup.com/wp-content/uploads/2021/11/accord_4-1.gif",
    header: "Big Data & Analytics",
    content: `Achieve lower costs, utmost scalability and maximum velocity with our legacy software modernization, cloud, and DevOps services. Our enterprise software development company is here to help you remove the roadblocks to innovation.`,
    idx: "04",
    project: "https://itrexgroup.com/services/technology-consulting/",
  },
  {
    elementSrc:
      "https://itrexgroup.com/wp-content/uploads/2021/11/accord_5-2.gif",
    header: "Artificial Intelligence",
    content: `Make your digital transformation journey super simple, safe, and aligned with your specific business needs. We're good at strategy planning for both enterprises and SMBs, IT audit, business process analysis, and more.`,
    idx: "05",
    project: "https://itrexgroup.com/services/technology-consulting/",
  },
  {
    elementSrc:
      "https://itrexgroup.com/wp-content/uploads/2021/11/accord_6-2.gif",
    header: "Extended Reality",
    content: `Achieve lower costs, utmost scalability and maximum velocity with our legacy software modernization, cloud, and DevOps services. Our enterprise software development company is here to help you remove the roadblocks to innovation.`,
    idx: "06",
    project: "https://itrexgroup.com/services/technology-consulting/",
  },
];

function Portfolio() {
  return (
    <RootGrid container id="use-cases">
      <Grid item xs={12} mt={5}>
        <Typography
          variant="h3"
          sx={{
            fontSize: { xs: "1.8rem", md: "3rem" },
            fontWeight: "600",
          }}
        >
          How we can help you
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ height: "4rem" }} />
      <Grid container sx={{ borderBottom: "1px solid #808080" }}>
        {portfolioElement.map((el) => (
          <GridItem container key={el.idx}>
            <Grid item xs={0} md={1}>
              <Stack sx={{ width: "2.5rem" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={el.elementSrc}
                  alt="accord_1-1"
                />
              </Stack>
            </Grid>
            <Grid item xs={0} md={5} />
            {/* <Grid item xs={11} md={5} sx={{ display: 'flex', flexDirection: 'row' , alignItems: 'center' }}>
            <Typography sx={{ display: {xs: 'none', md: 'flex'}, fontSize: '1rem', mr: 3 }}>{el.idx}</Typography>
            <Typography sx={{ fontSize: {xs: '1.25rem', md: '1.5rem'}, fontWeight: 600 }}>{el.header}</Typography>
          </Grid>
          <Grid
            item
            xs={1}
            md={1}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton>
              <ArrowDownward />{" "}
            </IconButton>
          </Grid> */}
            <Grid item xs={12} md={6}>
              <Accordion
                id="pg-accord"
                sx={{
                  boxShadow: "none",
                  border: "none",
                  background: "transparent",
                }}
              >
                <AccordionSummary
                  sx={{ padding: 0 }}
                  expandIcon={<ArrowDownward />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack sx={{ alignItems: 'baseline', flexDirection: 'row' }}>
                  <Typography
                    sx={{
                      display: { xs: "none", md: "flex" },
                      fontSize: {xs:"1rem", md: "1.25rem"},
                      mr: 3,
                    }}
                  >
                    {el.idx}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "1.25rem", md: "1.5rem" },
                      fontWeight: 600,
                    }}
                  >
                    {el.header}
                  </Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 0,
                    marginInline: { xs: "0", md: "3rem" },
                    width: { xs: "80%", md: "70%" },
                  }}
                >
                  <Typography>{el.content}</Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </GridItem>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ height: "4rem" }} />
      <Grid item xs={12}>
        {/* <Button
          sx={{
            background: "#4861FC",
            color: "#FFFFFF",
            padding: { xs: "1rem 2rem", md: "1rem 5rem" },
            textTransform: "none",
            fontSize: "1rem",
            width: { xs: "100%", md: "max-content" },
            '&:hover': {
              background: '#1e40af'
            }
          }}
        >
          Discuss my project
        </Button> */}
      </Grid>
      <Grid item xs={12} sx={{ height: "6rem" }} />
    </RootGrid>
  );
}

export default Portfolio;

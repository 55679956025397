import { Stack } from "@mui/material";

import Services from "../Services";
import Introduction from "../Introduction";
import Header from "../Header";
import AboutUs from "../AboutUs";
import { styled } from '@mui/material/styles';
import ContactUs from "../ContactUsPage";
// import Brand from "../Brand";
// import Prices from "../Prices";
import Challenges from "../Challenges";
import Expertise from "../Expertise";
import Process from "../Process";
import Team from "../Team";
import Portfolio from "../Portfolio";
import LatestProjects from "../LatestProjects";
import Collabrate from "../Collabrate";

/**
 * 
 * 
 * <div className="App">
      <Header />
      <Introduction />
      <Features />
      <Testimonials />
      <Footer />
    </div>
 */

    const RootStyle = styled(Stack)(({ theme }) => ({
      // [theme.breakpoints.up('xs')]: {
      //     paddingInline: '1rem',
      // },
      // [theme.breakpoints.up('md')]: {
      //   paddingInline: '2rem',
      // },
      // maxWidth: '1240px',
      overflowX: 'hidden',

  }));

const Home = () => {
  return (
    <RootStyle className="App-home">
      <Header />
      <Introduction />
      <Portfolio />
      <LatestProjects />
      <Collabrate />
      <Team />
      {/* <ContactUs /> */}
      <AboutUs />
    </RootStyle>
  );
};

export default Home;

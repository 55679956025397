import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import styles from "./styles.module.css";
import { styled } from "@mui/material/styles";

const ProcessDescriptionTypo = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    fontSize: "0.65rem",
    padding: "0.2rem", 
    width: "90px",
    lineHeight: 1.2,
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "0.75rem",
    padding: "0.5rem", 
    width: "225px"
  },
  
}));

function Process() {
  return (
    <Grid container sx={{mt:{xs:6, md:12}, fontFamily: 'Roboto, sans-serif',}}>
      <Grid item xs={1} />
      <Grid item xs={12} md={5} mt={6}>
        <Stack
          sx={{
            ml: {xs: 0, md: 4},
            height: {xs:"13.125rem", md:"15.625rem"},
            width: {xs:"13.125rem", md:"15.625rem"},
            borderRadius: "50%",
            position: "relative",
            background: `radial-gradient(circle at 0% 0%, rgb(246, 0, 254) 0%, rgb(201, 0, 254) 25%, rgb(161, 54, 255) 50%, rgb(81, 66, 240) 75%, rgb(0, 51, 217) 100%)`,
          }}
        >
          <Stack
            id="process-pg"
            className={styles["pop-one"]}
            sx={{ left: "85%", top: "-10%", flexDirection: "row" }}
          >
            <Stack
              className={styles["chat-bubble"]}
              sx={{ height: "60px", width: "60px" }}
            >
              <p>01</p>
            </Stack>
            <ProcessDescriptionTypo>
              {" "}
              Assessment: Determine business needs and requirements{" "}
            </ProcessDescriptionTypo>
          </Stack>

          <Stack
            id="process-pg2"
            className={styles["pop-one"]}
            sx={{ left: "102%", top: "19%", flexDirection: "row" }}
          >
            <Stack
              className={styles["chat-bubble"]}
              sx={{
                height: "60px",
                width: "60px",
                "&::before": {
                  left: "-25%",
                  bottom: "10%",
                  transform: "rotate(67deg)",
                },
              }}
            >
              <p>02</p>
            </Stack>
            <ProcessDescriptionTypo>
              {" "}
              Data Collection - Obtain, clean and label data{" "}
            </ProcessDescriptionTypo>
          </Stack>

          <Stack
            id="process-pg3"
            className={styles["pop-one"]}
            sx={{ flexDirection: "row", left: "104%", top: "50%" }}
          >
            <Stack
              className={styles["chat-bubble"]}
              sx={{
                "&::before": {
                  left: "-29%",
                  bottom: "45%",
                  transform: "rotate(100deg)",
                  borderColor:
                    "rgb(201, 0, 254) transparent transparent transparent",
                },
              }}
            >
              <p>03</p>
            </Stack>
            <ProcessDescriptionTypo>
            Maintenance - Monitor, recalibrate, and update models
            </ProcessDescriptionTypo>
          </Stack>

          <Stack
            id="process-pg4"
            className={styles["pop-one"]}
            sx={{ flexDirection: "row", left: "90%", top: "81%" }}
          >
            <Stack
              className={styles["chat-bubble"]}
              sx={{
                "&::before": {
                  left: "-21%",
                  bottom: "68%",
                  borderColor:
                    "rgb(201, 0, 254) transparent transparent transparent",
                  transform: "rotate(122deg)",
                },
              }}
            >
              <p>04</p>
            </Stack>
            <ProcessDescriptionTypo>
              Model Development and Deployment: Build, train and optimize AI
              models and Integrate them into apps/software products
            </ProcessDescriptionTypo>
          </Stack>

          <Stack
            sx={{
              alignItems: "center",
              height: "100%",
              margin: "0.5rem",
              border: "0.25rem dotted #FFFFFF",
              borderRadius: "50%",
              position: "relative",
            }}
          >
            <Stack
              sx={{
                mt: 5,
                position: "relative",
                width: "40%",
                alignItems: "center",
              }}
            >
              <img
                style={{ objectFit: "contain", width: "100%" }}
                src="/honk.png"
                alt="honk"
              />
            </Stack>
            <Typography sx={{ mt: 1, color: "#FFFFFF", fontSize: "1.85rem" }}>
              Our Process
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            height: "75px",
            width: "200px",
            position: "relative",
            float: "left",
            overflow: "hidden",
          }}
        >
          <Stack
            sx={{
              transform: "rotate(-35deg)",
              position: "absolute",
              left: "-3.5rem",
              top: "0.25rem",
              height: "150px",
              width: "150px",
            }}
          >
            <img src="/pentagon.png" alt="pentagon" />
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={0}
        md={6}
        sx={{ display: { xs: "none", md: "block" }, overflow: "hidden" }}
      >
        <Stack sx={{ position: "relative", height: "50%", float: "right" }}>
          <Stack
            sx={{
              transform: "rotate(5deg)",
              position: "absolute",
              right: "-8rem",
              top: "-10.75rem",
              height: "400px",
              width: "400px",
            }}
          >
            <img
              style={{ objectFit: "contain" }}
              src="/pentagon.png"
              alt="pentagon"
            />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Process;

import React from "react";
import { Typography, Grid, Stack } from "@mui/material";
import { LinkedIn } from '@mui/icons-material';
import styles from './styles.module.css';

function Team() {
  return (
    <Grid container mt={12} sx={{ color: "rgb(33, 106, 217)", overflow: 'hidden', padding: {xs: '0 4rem',md: '0 7rem'} }}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Typography sx={{ fontSize: {xs: "2rem", md: "2.5rem"}, fontWeight: {xs: '600', md: '400'} }}>Meet our Team</Typography>
      </Grid>
      <Grid container mt={2}>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: {xs: 4, md: 0}
          }}
        >
          <Stack sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <img
              style={{
                objectFit: "cover",
                width: "175px",
                height: "175px",
                borderRadius: "15%",
              }}
              src="https://storage.googleapis.com/zaide-ai-prod.appspot.com/team/hemant.webp"
              alt="hemant"
            />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <a
              href="https://www.linkedin.com/in/hjai/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography>Hemant</Typography>
            </a>
            <LinkedIn ml={1} />
          </Stack>

          <Stack>
            <ul className={styles['custom-list']}>
              <li>Angel Investor Private Equity and Commercial Real Estate</li>
              <li>
                Was an embedded software engineer, worked for Yahoo, NEC and
                conexant Over 12 years After MBA (UC Irvine)
              </li>
              <li>
                Launched 3 businesses CEO ie IMPACT, Appraisal Technologies Inc
              </li>
            </ul>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: {xs: 4, md: 0}
          }}
        >
          <Stack sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <img
              style={{
                objectFit: "cover",
                width: "175px",
                height: "175px",
                borderRadius: "15%",
              }}
              src="https://storage.googleapis.com/zaide-ai-prod.appspot.com/team/Parth.webp"
              alt="Parth"
            />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <a
              href="https://www.linkedin.com/in/parthsharma16/"
              target="_blank"
              rel="noreferrer"
            >
              <Typography>Parth</Typography>
            </a>
            <LinkedIn ml={1} />
          </Stack>

          <Stack>
            <ul className={styles['custom-list']}>
              <li>MS in Entrepreneurship from Germany</li>
              <li>
                9 yrs in Robotics and AI - Indian Institute of Technologies
                Delhi
              </li>
              <li>CTO of 3 startups</li>
              <li>Represented India for IIoT(Robotics) BRICS</li>
              <li>Voice of Indian Founders and Students in Germany</li>
              <li>
                Worked with Forbes 30 Under 30 Awarded top 4 startups in OWL.
              </li>
            </ul>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: {xs: 4, md: 0}
          }}
        >
          <Stack sx={{ width: "100%", display: "flex", alignItems: "center" }}>
            <img
              style={{
                objectFit: "cover",
                width: "175px",
                height: "175px",
                borderRadius: "15%",
              }}
              src="https://storage.googleapis.com/zaide-ai-prod.appspot.com/team/Raj.webp"
              alt="Raj"
            />
          </Stack>
          <Stack sx={{ display: "flex", flexDirection: "row", mt: 2 }}>
            <Typography>Raj</Typography>
            <LinkedIn ml={1} />
          </Stack>

          <Stack>
            <ul className={styles['custom-list']}>
              <li>Entrepreneur First Cohort member 2019</li>
              <li>
                Dual masters in AI and robotics from TU Berlin and KTH Sweden
              </li>
              <li>Assistant Lecturer at Coventry University</li>
              <li>Mentor and alpha tester at Coursera for Machine learning</li>
            </ul>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Team;
